import React from 'react'
import img from '../../Assets/Poster.png'
import img2 from '../../Assets/golden.png'
import { Navbar2 } from '../../Components/Header/Navbar2';
import Footers2 from '../../Components/Footer/Footer2';

function Terms() {
    const divStyle = {
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

      };

      const divStyle2 = {
        backgroundImage: `url(${img2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

      };
    
      const containerStyle = {

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '600px', // Adjust the width as needed
        
      };

  return (
    <div className='bg-black text-white montserrat-text'>
        <Navbar2/>
      
    <div className="montserrat-text">
      <div className='px-6 py-12 flex justify-center'>
    <p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-white font-semibold uppercase w-fit">F.A.Q</p>
    </div>

    <div className='flex justify-center pb-16'>
    <div className="max-w-[1000px] w-[1000px]">
    <p className="py-6 pt-16 font-normal ">
    What Is Grand Gangsta City ($GGC)?
    </p>
    <p className="py-4 font-normal ">
    Grand Gangsta City - Where the Legends of the 1950s Come to Life on the Blockchain!
    
        </p>
        <p className="py-4 font-normal ">
        Step into the gritty, captivating world of Grand Gangsta City, a groundbreaking fusion of GTA and PUBG, meticulously designed for mobile phones and computers. Experience the allure of a bygone era, as you assume the identity of a character of your choosing in this 1950s Film Noir style Mafia game. Sans Francisco has been masterfully resurrected from the past, offering you a chance to live a fictitious life of crime or uphold the law – the choice is yours.

        </p>
        <p className="py-6 font-normal ">
        How do I play the game?    
        </p>
        <p className="py-4 font-normal ">
        You will be able to play the game simply by downloading on your Mobile Phone or computer.    

        </p>
        <p className="py-6 font-normal ">
        Will I require a wallet to play the game?

        </p>

        <p className="py-4 font-normal ">
        Yes you will require a wallet to play the game. However the game has an in-game built wallet which you can easily make an account through email.

        </p>
        <p className="py-6 font-normal ">
Does Grand Gangsta City have a token?        
        </p>
        <p className="py-4 font-normal ">

        Grand Gangsta City will soon be launched and it will have a token $GGC. Keep following us on social Media for more updates.        
        </p>
        <p className="py-6 font-normal ">
        What Features will Grand Gangsta City have?        
        </p>
        <p className="py-4 font-normal ">
        Grand Gangsta City will have multiple game modes like Time Trial, Battle Royal Mode and Quest Mode.

        </p>
        <p className="py-6 font-normal ">
        Is Grand Gangsta CIty free to play?                
        </p>
        <p className="py-4 font-normal ">
        Yes Grand Gangsta City will be a free to play game Battle Royal and Time Trial will be 100% free to play. With some quests free. More Quests will require tokens and NFTs.

        </p>
        <p className="py-4 font-normal ">
What NFTs Grand Gangsta City will have?                
        </p>
        <p className="py-4 font-normal ">
        Grand Gangsta City will have Lands, Skins, Weapons, Vehicles as NFTs.

        </p>
        <p className="py-6 font-normal ">
        What are NFT Patches?                        
        </p>
        <p className="py-4 font-normal ">
        NFT patches are bullets, Grenades, Food, and other consumables.                        
        </p>
        <p className="py-6 font-normal ">
        How will I buy NFT patches?                        
        </p>
        <p className="py-4 font-normal ">
        You will be able to buy NFT patches easily in-game or on secondary marketplaces.

        </p>
        <p className="py-6 font-normal ">
        Why are there lands in the game?                                
        </p>
        <p className="py-4 font-normal ">
        Lands will play a major role in Grand Gangsta City as owners will be able to build Factories, Restaurants, Shops, Clubs, Advertisement billboards on land and generate real revenue from it.

        </p>
        <p className="py-6 font-normal ">
When will I be able to buy NFT lands and when will I be able to build on it?                                
        </p>
        <p className="py-4 font-normal ">
        You will be able to build and buy on NFT lands after launch of Quest mode Refer to road map for more details.

        </p>
        <p className="py-6 font-normal ">
        When will tournaments take place?
        </p>
        <p className="py-4 font-normal ">
        Tournaments will happen every month, Quarterly, Half Yearly and a Grand Tournament every year.

        </p>
        <p className="py-6 font-normal ">
How do I reach out for marketing?
        </p>
        <p className="py-4 font-normal ">
        Please submit your request on website contact.

        </p>
        <p className="py-6 font-normal ">
        How will $GGC tokens be used in the game?
        </p>
        <p className="py-4 font-normal ">
        $GGC will be the main currencies used for buying NFTs, NFT patches and tournaments.
        </p>
        <p className="py-6 font-normal ">
        Where can I buy $GGC Tokens?
        </p>
        <p className="py-4 font-normal ">
        The GGC token hasn't launched yet. Stay tuned for updates on our official channels, including our website, Twitter, and Telegram. We'll provide details on how to purchase as we approach the launch. Thank you for your support!

        </p>
        <p className="py-6 font-normal ">
        Where can I buy GGC NFT&apos;s?        
        </p>
        <p className="py-4 font-normal ">
        Our GGC NFTs are still in the works and haven&apos;t been released yet. Keep an eye on our official channels—website, Twitter, and Telegram—for the latest updates. We'll share information on how to get your hands on them as the launch date nears. Thanks for your enthusiasm and support!

        </p>
        <p className="py-6 font-normal ">
        Does Grand Gangsta City have a Marketplace?        
        </p>
        <p className="py-4 font-normal ">
        Grand Gangsta City doesn&apos;t have a marketplace yet. Check our website, Twitter, and Telegram for updates on its launch. Thanks for your interest!

        </p>
    </div>
        </div>
    </div>
    <Footers2/>
    </div>
  )
}

export default Terms