import React from "react"
import eventhead from '../../Assets/img5.jpg'
import eventhead2 from '../../Assets/Quest.png'
import eventhead3 from '../../Assets/RedBtnBG 1.png'
import img1 from '../../Assets/bg2item.png'
import img2 from '../../Assets/bg3item.png'
import img3 from '../../Assets/bg4item.png'
import bgimg1 from '../../Assets/p1.png'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: `80vw`,
  bgcolor: 'black',
  border: '2px solid #000',
  boxShadow: 24,
  height: '80vh',
  p: 4,
  color: 'white'
};

const HeroSection6 = () => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

    const divStyle = {
        backgroundImage: `url(${eventhead})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed"

      };
      const divStyle2 = {
        backgroundImage: `url(${eventhead3})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

      };
    
      const containerStyle = {
        backgroundImage: `url(${eventhead2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        padding: '10px',
        width: '290px', // Adjust the width as needed
        
      };
      const containerStyle2 = {
        backgroundImage: `url(${img1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        padding: '10px',
        width: '290px', // Adjust the width as needed
        
      };
      const containerStyle3 = {
        backgroundImage: `url(${img2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        padding: '10px',
        width: '290px', // Adjust the width as needed
        
      };
      const containerStyle4 = {
        backgroundImage: `url(${img3})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        padding: '10px',
        width: '290px', // Adjust the width as needed
        
      };
  return (
    <>
    <div style={divStyle} className="px-8 py-24">
      <div className="pb-12 mx-24 w-[300px]">
    <p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-white text-white font-semibold uppercase">GamePlay Overview</p>
    </div>
    <div className='flex  justify-center  flex-wrap gap-6' >
        <div>
    <div style={containerStyle} className="text-white h-[500px]">
        <p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-white font-semibold">TIME TRIAL</p>
      </div>
      <button className="w-full  py-4 mt-6 text-white" onClick={handleOpen} style={divStyle2}>
        <p className="text-center text-[19px]" >EXPLORE</p></button>
      </div>
      <div>
    <div style={containerStyle2} className="text-white h-[500px]">
        <p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-white font-semibold">TEAM <br />DEATH MATCH</p>
      </div>
      <button className="w-full  py-4 mt-6 text-white" onClick={handleOpen2} style={divStyle2}>
        <p className="text-center text-[19px]" >EXPLORE</p></button>
      </div>
      <div>
    <div style={containerStyle3} className="text-white h-[500px]">
        <p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-white font-semibold">BATTLE <br />ROYALE</p>
      </div>
      <button className="w-full  py-4 mt-6 text-white" onClick={handleOpen3} style={divStyle2}>
        <p className="text-center text-[19px]">EXPLORE</p></button>
      </div>
      <div>
    <div style={containerStyle4} className="text-white h-[500px]">
        <p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-white font-semibold">OPEN <br /> WORLD QUEST</p>
      </div>
      <button className="w-full  py-4 mt-6 text-white" onClick={handleOpen4} style={divStyle2}>
        <p className="text-center text-[19px]">EXPLORE</p></button>
      </div>
      
    </div>

    </div>


    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

           <div className="block md:flex justify-center gap-20 items-center sm:mt-12">
            <img src={bgimg1} alt="img" className="h-[300px] w-[430px]" />
            <div className="flex flex-col gap-10">
              <div className=" flex justify-center">
            <p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-white font-semibold">TIME TRIAL</p>
            </div>
            <p className="text-center pr-8">
           Team Death match contains 5 v 5 team match, with upto 3 maps and 10 minutes of gameplay. Custom Lobbies & Public Lobbies are available to play.
           </p>
           </div>
           </div>

        </Box>
      </Modal>
      
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

           <div className="block md:flex justify-center gap-20 items-center sm:mt-12">
            <img src={bgimg1} alt="img" className="h-[300px] w-[430px]" />
            <div className="flex flex-col gap-10">
              <div className=" flex justify-center">
            <p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-white font-semibold">TEAM DEATH MATCH</p>
            </div>
            <p className="text-center pr-8">
           Team Death match contains 5 v 5 team match, with upto 3 maps and 10 minutes of gameplay. Custom Lobbies & Public Lobbies are available to play.
           </p>
           </div>
           </div>

        </Box>
      </Modal>
      <Modal
        open={open3}
        onClose={handleClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

           <div className="block md:flex justify-center gap-20 items-center sm:mt-12">
            <img src={bgimg1} alt="img" className="h-[300px] w-[430px]" />
            <div className="flex flex-col gap-10">
              <div className=" flex justify-center">
            <p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-white font-semibold">BATTLE ROYALE</p>
            </div>
            <p className="text-center pr-8">
           Team Death match contains 5 v 5 team match, with upto 3 maps and 10 minutes of gameplay. Custom Lobbies & Public Lobbies are available to play.
           </p>
           </div>
           </div>

        </Box>
      </Modal>
      <Modal
        open={open4}
        onClose={handleClose4}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

           <div className="block md:flex justify-center gap-20 items-center sm:mt-12">
            <img src={bgimg1} alt="img" className="h-[300px] w-[430px]" />
            <div className="flex flex-col gap-10">
              <div className=" flex justify-center">
            <p className="text-[25px] border-t-[1px] border-b-[1px] py-[1px] border-white font-semibold">BATTLE ROYALE QUEST</p>
            </div>
            <p className="text-center pr-8">
           Team Death match contains 5 v 5 team match, with upto 3 maps and 10 minutes of gameplay. Custom Lobbies & Public Lobbies are available to play.
           </p>
           </div>
           </div>

        </Box>
      </Modal>
    </>
  )
}

export default HeroSection6;