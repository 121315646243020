import React from "react"
import eventhead from '../../Assets/imgn3.png'
import eventhead2 from '../../Assets/Yello_Btn_BG.png'
import img1 from '../../Assets/4.jpg'

const HeroSection5 = () => {
    const divStyle = {
        backgroundImage: `url(${eventhead})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed"

      };
      const divStyle3 = {
        backgroundImage: `url(${img1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed"

      };
      const divStyle2 = {
        backgroundImage: `url(${eventhead2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",


      };
    
      const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '600px', // Adjust the width as needed
        
      };
    
  return (
    <>

<div style={divStyle3} className=" ">
    <div className='flex  justify-between sm:gap-20 h-[100vh] flex-wrap pb-16' >
    <div style={containerStyle} className="text-white text-right">

      </div>
      <div style={containerStyle} className="text-white text-left">
        <div style={divStyle2} className="py-6 px-10 text-left text-[40px] font-bold w-full">
        VISION
        </div>

        <p className="space-y-4 mt-6">
            <p>
            GGC is a living and breathing ecosystem of players and owners that work in cooperation to achieve their goals</p>

        </p>

      </div>
      
    </div>

    </div>


    <div style={divStyle} className=" ">
    <div className='flex  justify-between gap-20 sm:h-[100vh] flex-wrap pt-12 pb-16' >
    <div style={containerStyle} className="text-white text-right">

      </div>
      <div style={containerStyle} className="text-white text-left">
        <div style={divStyle2} className="py-6 px-10 text-left uppercase text-[40px] font-bold w-full">
        Bridging the gap from 
Web2 to Web3
        </div>

        <p className="space-y-4 mt-6">
            <p>
            Empowering gamers to own their digital assets and experience the future of gaming. 
            Dive into immersive gameplay that rewards you. From casual gamer to crypto enthusiast, there's a place for everyone.
            </p>
        </p>

      </div>
      
    </div>

    </div>
    </>
  )
}

export default HeroSection5;