import React from 'react'
import { Footer } from "flowbite-react";
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter ,BsMedium, BsTelegram , BsDiscord} from "react-icons/bs";
import logo from '../../Assets/logo.png'
import { FaRedditAlien } from "react-icons/fa6";
import { FaMedium } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";

import footerbanner from '../../Assets/footerbanner.png'


function Footers2() {
  const divStyle = {
    backgroundImage: `url(${footerbanner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",

  };

  return (
    <>
    <div className=' py-16 -mt-8' style={divStyle}>
         <div className='flex flex-wrap justify-center gap-12 items-center'>
          <div>
            <img src={logo} alt="logo" className='w-[160px] h-[120px]' />
          </div>

          <div className='flex flex-col justify-center gap-4 text-white lg:border-l-[1px] lg:pl-16 lg:pr-24 border-[#181818]'>
            <p className='font-extrabold'>
            Connect with GANGSTA
            </p>
            <div className='flex gap-6'>
            <Link to={`https://t.me/Grandgangstacity`} target='_blank'>
          <FaTelegramPlane className='text-white text-[22px]'/>
          </Link>
          <Link to={`https://discord.gg/grandgangstacity`} target='_blank'>
          <FaDiscord className='text-white text-[22px]'/>
          </Link>
          <Link to={`https://twitter.com/grandgangstaci`} target='_blank'>
          <FaXTwitter className='text-white text-[22px]'/>
          </Link>
          <Link to={`https://www.youtube.com/@grandgangstacity?si=af2nychVQEcxukjo`} target='_blank'>
          <IoLogoYoutube className='text-white text-[22px]'/>
          </Link>
          <Link to={`https://www.linkedin.com/company/grandgangstacity/`} target='_blank'>
          <FaLinkedinIn className='text-white text-[22px]'/>
          </Link>
            </div>

            <div className='flex gap-6'>
            <Link to={`https://www.instagram.com/grandgangstacity/`} target='_blank'>
          <FaInstagram className='text-white text-[22px]'/>
          </Link>
          <Link to={`https://zealy.io/cw/grandgangstacity-0098`} target='_blank'>
          <FaRedditAlien className='text-white text-[22px]'/>
          </Link>
          <Link to={`https://www.facebook.com/grandgangstacity?mibextid=LQQJ4d`} target='_blank'>
          <FaFacebookF className='text-white text-[22px]'/>
          </Link>
          <Link to={`https://medium.com/@grandgangstacity`} target='_blank'>
          <FaMedium className='text-white text-[22px]'/>
          </Link>
          <Link to={`https://www.tiktok.com/@grandgangstacity`} target='_blank'>
          <FaTiktok className='text-white text-[22px]'/>
          </Link>
            </div>
          </div>

          <div className='flex flex-col justify-center gap-2 text-white' >
            <Link to='#'><p className='font-light text-[14px]'>TON Mini Game</p></Link>
            <Link to='#'><p className='font-light text-[14px]'>Whitepaper</p></Link>
            <Link to='#'><p className='font-light text-[14px]'>Tokenomics</p></Link>
            <Link to='#'><p className='font-light text-[14px]'>Partnerships</p></Link>

          </div>
          <div className='flex flex-col justify-center gap-2 text-white lg:border-l-[1px] lg:pl-12 border-[#181818]' >
            <Link to='/about'><p className='font-light text-[14px]'>About Us</p></Link>
            <Link to='/team'><p className='font-light text-[14px]'>Our Team</p></Link>
            <Link to='/privacy'><p className='font-light text-[14px]'>Privacy Policy</p></Link>
            <Link to='/faqs'><p className='font-light text-[14px]'>FAQ</p></Link>

          </div>

          <div className='flex flex-col gap-3' >
            <input type="text" placeholder='Enter Your E-mail' name='email' id='email' className="rounded-full text-black text-[14px] px-2 py-1" />
            <button className='w-[100%] bg-[#BA1010] rounded-full text-white text-[14px] py-1'>Subscribe</button>

          </div>
         </div>
    </div>

    <div className='flex justify-center bg-[#BA1010] py-2 text-[14px] text-white'>© 2024  <Link to='#' target='_blank'> BrandSteps  </Link> All Right Reserved.</div>
    </>
  )
}

export default Footers2