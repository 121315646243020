import React from 'react'
import HeroSection1 from '../../Components/HomeSections/Herosection1/HeroSection1'
import HeroSection2 from '../../Components/HomeSections/HeroSection2'
import HeroSection3 from '../../Components/HomeSections/HeroSection3'
import HeroSection4 from '../../Components/HomeSections/HeroSection4'
import HeroSection5 from '../../Components/HomeSections/HeroSection5'
import Headers from '../../Components/Header/Headers'
import SocialSection from '../../Components/Header/SocialSection'
import HeroSection6 from '../../Components/HomeSections/HeroSection6'
import HeroSection7 from '../../Components/HomeSections/HeroSection7'
import HeroSection9 from '../../Components/HomeSections/HeroSection9'
import HeroSection10 from '../../Components/HomeSections/HeroSection10'
import { Navbar2 } from '../../Components/Header/Navbar2'
import HeroSection8 from '../../Components/HomeSections/HeroSection8'
import Footers from '../../Components/Footer/Footers'
import HomeSectionchar from '../../Components/HomeSections/HomeSectionchar'
import Footers2 from '../../Components/Footer/Footer2'

function HomePage() {
  return (
    <div>
      <Navbar2/>
        <HeroSection1/>
        <SocialSection/>
        <HeroSection2/>
        <HeroSection3/>
        <HeroSection4/>
        <HeroSection5/>
        <HeroSection6/>  
        <HeroSection7/>
       {/*} <HeroSection8/>*/}
        <HeroSection9/>
        <HomeSectionchar/>
        <HeroSection10/>
        
        <Footers2/>

    </div>
  )
}

export default HomePage