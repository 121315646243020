import React from 'react'
import social1 from '../../Assets/mediumwhite.png'
import social2 from '../../Assets/discordwhite.png'
import social3 from '../../Assets/instagramwhite.png'
import social4 from '../../Assets/twitterwhite.png'
import social5 from '../../Assets/telegramWhite.png'

import bglogo from '../../Assets/socialbg.png'
import recbglogo from '../../Assets/Rectangle.png'
import { FaRedditAlien } from "react-icons/fa6";
import { FaMedium } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";

export default function SocialSection() {

  const divStyle = {
    backgroundImage: `url(${bglogo})`,
    backgroundSize: "contain", // Ensure the entire image fits within the div
    backgroundPosition: "center",
  };

  return (
    <div className="flex justify-center sm:px-6 py-6 mt-[-3px] mb-[-4px]" style={divStyle}>
        <div className='flex justify-center items-center gap-4 sm:gap-16'>
        <Link to={`https://t.me/Grandgangstacity`} target='_blank'>
          <FaTelegramPlane className='text-white text-[25px]'/>
          </Link>
          
          <Link to={`https://discord.com/invite/grandgangstacity`} target='_blank'>
          <FaDiscord className='text-white text-[25px]'/>
          </Link>
          <Link to={`https://www.instagram.com/grandgangstacity/`} target='_blank'>
          <FaInstagram className='text-white text-[25px]'/>
          </Link>
          <Link to={`https://twitter.com/grandgangstaci`} target='_blank'>
          <FaXTwitter className='text-white text-[25px]'/>
          </Link>
          <Link to={`https://www.youtube.com/@grandgangstacity?si=af2nychVQEcxukjo`} target='_blank'>
          <IoLogoYoutube className='text-white text-[25px]'/>
          </Link>
          <Link to={`https://www.linkedin.com/company/grandgangstacity/`} target='_blank'>
          <FaLinkedinIn className='text-white text-[25px]'/>
          </Link>
          <Link to={`https://zealy.io/cw/grandgangstacity-0098`} target='_blank'>
          <FaRedditAlien className='text-white text-[25px]'/>
          </Link>
          <Link to={`https://www.facebook.com/grandgangstacity?mibextid=LQQJ4d`} target='_blank'>
          <FaFacebookF className='text-white text-[25px]'/>
          </Link>
          <Link to={`https://medium.com/@grandgangstacity`} target='_blank'>
          <FaMedium className='text-white text-[25px]'/>
          </Link>

        </div>
    </div>
  )
}
